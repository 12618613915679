import {
	createWebstoreConfig,
	getEcommStorenameAvailableFromCookies,
	getProfileFromCookies,
	getWebstoreConfigByOwner,
	updateDraftWebstoreConfig,
	updateWebstoreConfig,
} from "@/api/clientSafe"
import { PermalinkResponseCodes, PermalinkStatus, Routes, WebstoreConfigurationStep, WebstoreFulfilment } from "@/enums"
import { useDeliveryFeeValues } from "@/hooks/useDeliveryFeeValues"
import { usePickupInstructionsValues } from "@/hooks/usePickupInstructionsValues"
import {
	ActionType,
	builderErrorStateAtom,
	builderMenuReducerAtom,
	builderModeEnabledAtom,
	builderWebconfigStateAtom,
	EditItem,
	formControlAtom,
	fulfilmentAtom,
	fullRefreshAtom,
	initialWebconfigDefaults,
	pickupAndDeliveryFormControlsAtom,
	productsCheckedAtom,
	servicesCheckedAtom,
	setupWebstoreNameAtom,
} from "@/state"
import { GetWebstoreConfigResponse, ProductId, UpdatePermalinkResponse, WebstoreConfig } from "@/types"
import { getStaleTimeInSeconds, priceToCent } from "@/utils"
import { useIsMutating, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import cloneDeep from "lodash.clonedeep"
import isEqual from "lodash.isequal"
import merge from "lodash.merge"
import { useSearchParams } from "next/navigation"
import React from "react"
import { useGetUserQuery, useUserDetails } from "."

export enum WebstoreQueriesKey {
	WebstoreConfig = "webstoreConfig",
	Draft = "draft",
	Update = "update",
	Live = "live",
	LatestError = "latestError",
	StoreNameAvailable = "storeNameAvailable",
}

/**
 * 10 minutes
 */
const defaultStaleTime = getStaleTimeInSeconds(60 * 10)

export const useGetEcommStorenameAvailable = (
	merchantId: string,
	storeId: string,
	newStoreName: string,
	enabled: boolean = true,
) => {
	const getEcommStorenameAvailableQuery = useQuery({
		queryKey: [WebstoreQueriesKey.StoreNameAvailable, newStoreName],
		queryFn: () => getEcommStorenameAvailableFromCookies(merchantId, storeId, newStoreName),
		staleTime: getStaleTimeInSeconds(60),
		enabled,
		retry: false,
		refetchInterval: defaultStaleTime,
	})

	return getEcommStorenameAvailableQuery
}

/**
 * Invalidate when
 * 1. Published version is updated
 * @param owner
 * @returns
 */
export const useGetWebstoreConfigByOwner = (owner: string) => {
	const getWebstoreConfigByOwnerQuery = useQuery({
		queryKey: [WebstoreQueriesKey.WebstoreConfig, WebstoreQueriesKey.Live],
		queryFn: () => getWebstoreConfigByOwner(owner),
		staleTime: defaultStaleTime,
		retry: false,
		enabled: !!owner,
	})

	return getWebstoreConfigByOwnerQuery
}

/**
 * Abstraction over useGetWebstoreConfigByOwner
 */
export const useGetWebstoreConfig = () => {
	const { owner } = useUserDetails()
	const { data } = useGetWebstoreConfigByOwner(owner ?? "")

	return {
		webstoreConfigDraft: data?.webstoreConfigDraft,
		webstoreConfig: data?.webstoreConfig,
		webstoreName: data?.webstoreName,
		isMerchantCompliant: data?.isMerchantCompliant,
	}
}

export const useGetUserProfile = () => {
	const { data, error: userError, refetch: userRefetch, isFetching: userFetching } = useGetUserQuery()

	const owner = data?.owner
	const merchantId = (owner ?? "").split("-")[1]

	const {
		data: profile,
		isFetching: profileFetching,
		error: profileError,
		refetch: profileRefetch,
	} = useQuery({
		queryKey: ["getUserProfile"],
		queryFn: () => getProfileFromCookies(merchantId),
		staleTime: defaultStaleTime,
		retry: false,
		enabled: !!data?.owner,
	})

	const retry = React.useCallback(() => {
		userRefetch().then(() => profileRefetch())
	}, [userRefetch, profileRefetch])

	return {
		retry,
		profile,
		isDataReady: !userFetching && !profileFetching,
		errors: [userError, profileError].filter(Boolean) as Error[],
	}
}

/**
 * Should only be called on PUBLISHING the store.
 * Draft updates should not use this mutation.
 *
 * Invalidates
 * 1. Live version
 * 2. Draft version
 * @param owner
 * @param webstoreName
 * @returns
 */
export const useUpdateWebstoreConfigMutation = (owner: string, webstoreName: string) => {
	const queryClient = useQueryClient()
	const { refreshLive, refreshDraft } = useWebstoreQueryCache()

	const updateWebstoreConfigMutation = useMutation({
		mutationFn: async ({ config, status }: { config: WebstoreConfig; status: PermalinkStatus }) => {
			const existingData = queryClient.getQueryData<GetWebstoreConfigResponse | undefined>([
				WebstoreQueriesKey.WebstoreConfig,
			])?.webstoreConfig

			if (!isEqual(config, existingData)) {
				const response = await updateWebstoreConfig(owner, webstoreName, config, status)

				// Anything other than Perm_00 is an error
				if (response.responseCode !== PermalinkResponseCodes.Perm_00) {
					throw new Error(`Failed to update webstore config. ${response.responseCode}: ${response.responseMessage}`)
				}
				return response
			}

			return existingData
		},
		onSuccess: () => {
			refreshLive()
			refreshDraft()
		},
	})

	return updateWebstoreConfigMutation
}

const updateDraftQueryKey = [WebstoreQueriesKey.WebstoreConfig, WebstoreQueriesKey.Update, WebstoreQueriesKey.Draft]

/**
 * Updates the draft version of the website's config.
 * It will only fire off a request if it's changed since the previous call.\
 * The draft version is pulled from the builderWebconfigStateAtom which is changed whenever
 * components are edited.
 *
 * Updates cache:
 * 1. Draft version
 * @returns
 */
export const useUpdateDraftWebstoreConfigMutation = () => {
	const queryClient = useQueryClient()
	const webstoreName = useGetWebstoreConfig().webstoreName ?? ""
	const builderWebstoreConfigValues = useBuilderWebstoreConfigValues()
	const { shouldDraftSave, setDraftConfigUpdateError } = useWebstoreQueryCache()

	const { data: getUserData, error: getUserError, isFetching: getUserIsFetching } = useGetUserQuery()
	const owner = getUserData?.owner ?? ""

	const updateDraftWebstoreConfigMutation = useMutation({
		mutationKey: updateDraftQueryKey,
		mutationFn: async () => {
			// TO DO: debounce times?
			const { cacheDataResponse, shouldTriggerUpdate } = shouldDraftSave()

			if (shouldTriggerUpdate) {
				const response = await updateDraftWebstoreConfig(owner, webstoreName, builderWebstoreConfigValues)

				// Anything other than Perm_00 is an error
				if (response.responseCode !== PermalinkResponseCodes.Perm_00) {
					throw new Error(`Failed to update draft webstore config. ${response.responseCode}: ${response.responseMessage}`)
				}

				queryClient.setQueryData(updateDraftQueryKey, response)
				return response
			}

			return cacheDataResponse
		},
		onSuccess: () => {
			setDraftConfigUpdateError(null)
			queryClient.invalidateQueries({ queryKey: [WebstoreQueriesKey.WebstoreConfig, WebstoreQueriesKey.Live] })
		},
		onError: setDraftConfigUpdateError,
	})

	const errors = [getUserError, updateDraftWebstoreConfigMutation.error].filter(Boolean) as Error[]

	return {
		...updateDraftWebstoreConfigMutation,
		owner,
		errors,
		isReady: !getUserIsFetching,
	}
}

export const useBuilderWebstoreConfigValues = () => {
	const { values } = useAtomValue(formControlAtom)

	const serviceIds = useAtomValue(servicesCheckedAtom)
	const productIds = useAtomValue(productsCheckedAtom)

	const fulfilmentProcess = useAtomValue(fulfilmentAtom)
	const pickupInstructions = usePickupInstructionsValues()
	const deliveryFee = useDeliveryFeeValues()

	const webstoreConfig = {
		...values,
		shipping: {
			fulfilmentProcess,
			pickupInstructions:
				fulfilmentProcess === WebstoreFulfilment.PICKUP || fulfilmentProcess === WebstoreFulfilment.PICKUP_OR_DELIVERY
					? pickupInstructions
					: "",
			deliveryFee:
				fulfilmentProcess === WebstoreFulfilment.DELIVERY || fulfilmentProcess === WebstoreFulfilment.PICKUP_OR_DELIVERY
					? priceToCent(Number(deliveryFee))
					: 0,
		},
		catalogueItems: {
			serviceIds: serviceIds,
			productIds: mappedProductIds(productIds),
		},
	} as WebstoreConfig

	return webstoreConfig
}

export const useFinishComponentDraftEdit = () => {
	const dispatch = useAtom(builderMenuReducerAtom)[1]
	const { mutate: persistDraftVersion } = useUpdateDraftWebstoreConfigMutation()

	return React.useCallback(() => {
		dispatch({ type: ActionType.EditItem, payload: { editItemType: EditItem.None } })
		// Trigger saving the updated state to draft
		persistDraftVersion()
	}, [persistDraftVersion])
}

/**
 * Will only ever be called one when the user is creating a brand new webstore.
 * @param owner
 * @returns
 */
export const useCreateWebstoreConfigMutation = (owner: string) => {
	const { clear } = useWebstoreQueryCache()

	const createWebstoreConfigMutation = useMutation({
		mutationFn: async (params: { webstoreName: string; currentStep: WebstoreConfigurationStep }) => {
			const response = await createWebstoreConfig(owner, params.webstoreName, params.currentStep)

			// Anything other than Perm_00 is an error
			if (response.responseCode !== PermalinkResponseCodes.Perm_00) {
				throw new Error(`Failed to create webstore config. ${response.responseCode}: ${response.responseMessage}`)
			}

			return response
		},
		onSuccess: clear,
	})

	return createWebstoreConfigMutation
}

/**
 * Determines the current required or last saved step in the store setup flow.\
 * If no webstoreConfig is found, the user is on the first step.
 * Else, use the saved currentStep from the webstoreConfig.
 *
 * Should only be used in the store setup flow.\
 * ℹ️ Instead of doing the redirection, the caller can do it.
 * @returns The current step in the store setup flow and a boolean indicating if the data is still loading.
 */
export const useCurrentOnboardingStepUrlPath = () => {
	const { owner, error: userDataError, isFetching: userDataFetching, refetch: retryUser } = useGetUserQuery()
	const {
		isFetching: webstoreConfigFetching,
		error: webstoreConfigError,
		data,
		refetch: retryWebstoreConfig,
	} = useGetWebstoreConfigByOwner(owner ?? "")

	const error = userDataError ?? webstoreConfigError
	const fetching = userDataFetching || webstoreConfigFetching
	const isFetchSuccessful = !fetching && error === null && data !== undefined

	const path = React.useRef<string>(Routes.Setup_Name)

	if (isFetchSuccessful) {
		const { responseCode, webstoreConfig } = data
		if (responseCode === PermalinkResponseCodes.Perm_01) {
			path.current = Routes.Setup_Name
		}

		if (responseCode === PermalinkResponseCodes.Perm_00) {
			path.current =
				webstoreConfig!.currentStep === WebstoreConfigurationStep.NAME_SELECTION ? Routes.Setup_Name : Routes.Setup_Catalogue
		}
	}

	const retry = React.useCallback(() => {
		retryUser().then(() => retryWebstoreConfig())
	}, [retryUser, retryWebstoreConfig])

	return { loading: fetching, requiredPath: path.current, error, retry }
}

export const useInitializeBuilderState = () => {
	const setBuilderModeEnabled = useSetAtom(builderModeEnabledAtom)
	const setBuilderWebconfigAtom = useSetAtom(builderWebconfigStateAtom)
	const setWebstoreNameAtom = useSetAtom(setupWebstoreNameAtom)
	const setProductsChecked = useSetAtom(productsCheckedAtom)
	const setServicesChecked = useSetAtom(servicesCheckedAtom)
	const setErrorAtom = useSetAtom(builderErrorStateAtom)
	const setBuilderMenu = useSetAtom(builderMenuReducerAtom)
	const setFullRefreshAtom = useSetAtom(fullRefreshAtom)
	const [fulfilmentProcess, setFulfilmentProcess] = useAtom(fulfilmentAtom)
	const { fieldErrors } = useAtomValue(formControlAtom)
	const { handleOnChange } = useAtomValue(pickupAndDeliveryFormControlsAtom)

	const tab = useSearchParams().get("tab")

	const { owner, isFetching: userDataFetching, error: userError, refetch: retryUserData } = useGetUserQuery()

	const {
		data: webstoreConfigResponse,
		isFetching: webstoreConfigFetching,
		error: webstoreConfigError,
		refetch: retryWebstoreConfig,
	} = useGetWebstoreConfigByOwner(owner ?? "")

	const retry = React.useCallback(() => {
		retryUserData().then(() => retryWebstoreConfig())
	}, [retryUserData, retryWebstoreConfig])

	const dataReady = !userDataFetching && !webstoreConfigFetching
	const error = webstoreConfigError ?? userError

	React.useEffect(() => {
		setBuilderModeEnabled(true)
	}, [])

	React.useEffect(() => {
		if (error) return
		let initialStateToUse = cloneDeep(initialWebconfigDefaults)

		// We merge with the default initial state in case the user has no webstore config yet.
		if (webstoreConfigResponse?.webstoreConfigDraft) {
			initialStateToUse = merge(initialStateToUse, webstoreConfigResponse.webstoreConfigDraft)
		} else {
			console.debug("No draft found for builder initialisation. Using live version instead.")
			initialStateToUse = merge(initialStateToUse, webstoreConfigResponse?.webstoreConfig)
		}

		if (dataReady && !error && initialStateToUse) {
			console.debug("Setting builder state", initialStateToUse)
			setBuilderWebconfigAtom({
				...initialStateToUse,
				catalogueItems: webstoreConfigResponse?.webstoreConfigDraft?.catalogueItems ?? {
					productIds: [],
					serviceIds: [],
				},
			})
			setWebstoreNameAtom(webstoreConfigResponse?.webstoreName ?? "")

			const catalogueItems = webstoreConfigResponse?.webstoreConfigDraft?.catalogueItems ?? { productIds: [], serviceIds: [] }
			setProductsChecked(
				catalogueItems.productIds.flatMap((product) => {
					if (product.variantIds.length === 0) {
						return product.id
					}

					return product.variantIds.map((variantId) => `${product.id}.${variantId}`)
				}),
			)
			setServicesChecked(catalogueItems.serviceIds)

			if (fulfilmentProcess === null) {
				setFulfilmentProcess(
					webstoreConfigResponse?.webstoreConfigDraft?.shipping?.fulfilmentProcess ?? WebstoreFulfilment.PICKUP,
				)
				handleOnChange("deliveryFee")(webstoreConfigResponse?.webstoreConfigDraft?.shipping?.deliveryFee ?? 0)
			}

			if (tab !== null) {
				setBuilderMenu({ type: ActionType.EditItem, payload: { editItemType: EditItem.CatalogueItems } })
			}

			const isCompleteOrEmpty = (value: object | string | undefined) => {
				return !value ? undefined : false
			}
			// Initialize setup guide panel tick state
			console.debug("config", webstoreConfigResponse?.webstoreConfigDraft)
			setErrorAtom((prev) => ({
				...prev,
				[EditItem.CatalogueItems]: false,
				[EditItem.HeroContent]: fieldErrors.hero ? true : isCompleteOrEmpty(webstoreConfigResponse?.webstoreConfigDraft?.hero),
				[EditItem.AboutUs]: fieldErrors.aboutUs ? true : isCompleteOrEmpty(webstoreConfigResponse?.webstoreConfigDraft?.aboutUs),
				[EditItem.StoreDetails]: fieldErrors.contact
					? true
					: isCompleteOrEmpty(webstoreConfigResponse?.webstoreConfigDraft?.contact),
				[EditItem.Socials]: fieldErrors.socialPages
					? true
					: isCompleteOrEmpty(webstoreConfigResponse?.webstoreConfigDraft?.socialPages),
				[EditItem.HeroImage]: isCompleteOrEmpty(webstoreConfigResponse?.webstoreConfigDraft?.hero?.imageUrl),
			}))
		}
		if (dataReady) setFullRefreshAtom(false)
	}, [dataReady && error && webstoreConfigResponse?.webstoreConfig, fieldErrors])

	return { builderStateInitialized: dataReady, error, retry }
}

const isElementVisible = (element: Element) => {
	const rect = element.getBoundingClientRect()
	return rect.top >= 0 && rect.bottom <= window.innerHeight && rect.height <= window.innerHeight
}
const isElementInMiddleOfScreen = (element: Element) => {
	const rect = element.getBoundingClientRect()
	return rect.top >= window.innerHeight / 2 && rect.bottom <= window.innerHeight / 2
}

/**
 * Provides a function to scroll to a specific component on the page.
 * The scroll functionality is dependent on the relevant components having a `data-scroll-to` attribute.
 *
 * @returns An object containing the `scrollTo` function and the `componentOptions` object.
 */
export const useScrollToComponent = () => {
	const timeoutRef = React.useRef<number>()

	const dispatch = useAtom(builderMenuReducerAtom)[1]

	const selectDomElement = (componentDataScrollTo: string) =>
		document.querySelector(`[data-scroll-to='${componentDataScrollTo}']`)

	// Ensure that the timeout is cleared when the component is unmounted.
	React.useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				window.clearTimeout(timeoutRef.current)
			}
		}
	}, [])

	/**
	 * Scrolls to the specified component and optionally activates the edit menu after scrolling.
	 *
	 * @param targetComponent - The target component to scroll to.
	 * @param activateEditAfterScroll - Optional. Specifies whether to activate the edit menu after scrolling. Default is true.
	 */
	const scrollToComponent = (targetComponent: EditItem, activateEditAfterScroll: boolean = true) => {
		const target = selectDomElement(targetComponent)

		if (!target) {
			return
		}

		// If the element is already visible, activate the edit menu. No need to scroll.
		if (isElementInMiddleOfScreen(target) || isElementVisible(target)) {
			if (activateEditAfterScroll) {
				dispatch({ type: ActionType.EditItem, payload: { editItemType: targetComponent } })
			}
			return
		}

		if (activateEditAfterScroll) {
			timeoutRef.current = window.setTimeout(() => {
				dispatch({ type: ActionType.EditItem, payload: { editItemType: targetComponent } })
			}, 500)
		}

		target.scrollIntoView({ behavior: "smooth" })
	}

	return { scrollTo: React.useCallback(scrollToComponent, []), componentOptions: EditItem }
}

export const useWebstoreQueryCache = () => {
	const queryClient = useQueryClient()
	const builderWebstoreConfigValues = useBuilderWebstoreConfigValues()

	const refreshLive = () =>
		queryClient.removeQueries({ queryKey: [WebstoreQueriesKey.WebstoreConfig, WebstoreQueriesKey.Live], exact: true })

	const refreshDraft = () => queryClient.removeQueries({ queryKey: updateDraftQueryKey, exact: true })

	const setDraftConfigUpdateError = (error: Error | null) => {
		queryClient.setQueryData([...updateDraftQueryKey, WebstoreQueriesKey.LatestError], error)
	}

	const draftConfigUpdateSaving = useIsMutating({ mutationKey: updateDraftQueryKey }) > 0
	const draftConfigUpdateError = queryClient.getQueryData<Error | undefined | null>([
		...updateDraftQueryKey,
		WebstoreQueriesKey.LatestError,
	])

	const clear = () => queryClient.clear()

	const shouldDraftSave = () => {
		const existingData = queryClient.getQueryData<UpdatePermalinkResponse | undefined>(updateDraftQueryKey)

		if (!existingData?.permalink?.webstoreConfigDraft) {
			return { shouldTriggerUpdate: true, cacheDataResponse: undefined }
		}

		return {
			shouldTriggerUpdate: !isEqual(builderWebstoreConfigValues, existingData.permalink.webstoreConfigDraft),
			cacheDataResponse: existingData,
		}
	}

	const liveWebconfig = queryClient.getQueryData<GetWebstoreConfigResponse | undefined>([
		WebstoreQueriesKey.WebstoreConfig,
		WebstoreQueriesKey.Live,
	])?.webstoreConfig

	let liveOutdated = false

	if (liveWebconfig) {
		// isEqual doesn't seem to work at object level so we need to check each key.
		liveOutdated = Object.keys(builderWebstoreConfigValues).reduce<boolean>((acc, key) => {
			const builderValue = builderWebstoreConfigValues[key as keyof WebstoreConfig]
			const liveValue = liveWebconfig[key as keyof WebstoreConfig]
			return acc || !isEqual(builderValue, liveValue)
		}, false)
	}

	const isMutating = useIsMutating() > 0

	return {
		isMutating,
		refreshLive,
		refreshDraft,
		draftConfigUpdateSaving,
		draftConfigUpdateError,
		setDraftConfigUpdateError: setDraftConfigUpdateError,
		shouldDraftSave,
		liveOutdated,
		clear,
	}
}

/**
 * Map product and variants id array to API data structure
 * @param productIds
 */
const mappedProductIds = (productIds: string[]) => {
	const productsMap = new Map()

	return productIds.reduce((acc, product) => {
		if (!product.includes(".")) {
			acc.push({ id: product, variantIds: [] })
			return acc
		}

		const [productId, variantId] = product.split(".")

		const variantIds = productsMap.get(productId)

		if (variantIds) {
			variantIds.push(variantId)
			return acc
		}

		productsMap.set(productId, [variantId])
		acc.push({ id: productId, variantIds: productsMap.get(productId) ?? [] })

		return acc
	}, [] as ProductId[])
}

import TextField, { getMaxCharacterMutationFn, trim } from "@/components/TextField"
import { useHeroContentValues } from "@/hooks"
import { builderErrorStateAtom, EditItem, formControlAtom } from "@/state"
import { Hero } from "@/types"
import Stack from "@mui/material/Stack"
import { useAtomValue, useSetAtom } from "jotai"
import React from "react"

const headerMaxCharacterCount = 60
const subheadingMaxCharacterCount = 100

const ofMaxCharactersText = (currentLength: number, maxLength: number) => `Max characters ${currentLength}/${maxLength}`

const blurMutationFns = [trim]
const headerChangeMutationFns = [getMaxCharacterMutationFn(headerMaxCharacterCount)]
const subheadingChangeMutationFns = [getMaxCharacterMutationFn(subheadingMaxCharacterCount)]

export const HeroContent = () => {
	const setErrorAtom = useSetAtom(builderErrorStateAtom)

	const { header, subheading, imageUrl } = useHeroContentValues(true)

	const heroContent: Hero = { header, subheading, imageUrl }

	const { handleOnChange, fieldErrors } = useAtomValue(formControlAtom)

	const errorMessage = fieldErrors.hero?.message

	const fieldHasError = (field: "header" | "subheading") => {
		return fieldErrors.hero?.path === field
	}

	const getErrorMessage = (field: "header" | "subheading"): string | undefined => {
		return fieldHasError(field) ? errorMessage : undefined
	}

	React.useEffect(() => {
		setErrorAtom((prev) => ({
			...prev,
			[EditItem.HeroContent]: fieldHasError("header") || fieldHasError("subheading"),
		}))
	}, [fieldErrors.hero])

	return (
		<Stack p="1rem">
			<TextField
				name="header"
				variant="filled"
				label="Header text (optional)"
				helperText={getErrorMessage("header") ?? ofMaxCharactersText(header?.length ?? 0, headerMaxCharacterCount)}
				onChange={(e) => {
					const update = handleOnChange("hero")
					update({ ...heroContent, header: e.target.value })
				}}
				value={header}
				fullWidth
				error={fieldErrors.hero?.path === "header"}
				size="medium"
				blurMutationFns={blurMutationFns}
				changeMutationFns={headerChangeMutationFns}
			/>

			<TextField
				name="subheading"
				variant="filled"
				label="Sub heading text (optional)"
				multiline
				helperText={getErrorMessage("subheading") ?? ofMaxCharactersText(subheading?.length ?? 0, subheadingMaxCharacterCount)}
				onChange={(e) => {
					const update = handleOnChange("hero")
					update({ ...heroContent, subheading: e.target.value })
				}}
				value={subheading}
				fullWidth
				error={fieldErrors.hero?.path === "subheading"}
				size="medium"
				blurMutationFns={blurMutationFns}
				changeMutationFns={subheadingChangeMutationFns}
				minRows={4}
			/>
		</Stack>
	)
}

import TextField from "@/components/TextField"
import { useAddressPredictions, useSelectedPlace } from "@/hooks"
import { Autocomplete } from "@mui/material"
import { useDebounce } from "@uidotdev/usehooks"
import React from "react"

type AddressProps = {
	onChange: (_address: string) => void
	initialValue: string
}

export const AddressComponent: React.FC<AddressProps> = ({ initialValue, onChange }) => {
	const [value, setValue] = React.useState(initialValue)
	const debounced = useDebounce(value, 300)

	const { predictions, addressChange, clear } = useAddressPredictions()
	const getAddress = useSelectedPlace()
	const [disableAutocomplete, setDisableAutocomplete] = React.useState(false)

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value)
		setDisableAutocomplete(false)
	}

	const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
		setDisableAutocomplete(false)
	}

	React.useEffect(() => {
		onChange(value)
	}, [value])

	React.useEffect(() => {
		if (!disableAutocomplete) {
			addressChange(debounced)
		}
	}, [debounced])

	const formatAddress = (addressParts: string[]) =>
		addressParts
			.map((part) => part.trim())
			.filter((part) => part)
			.join(", ")

	const getHandleSelect = (placeId: string) => () => {
		getAddress(placeId, (result) => {
			clear()
			setDisableAutocomplete(true)
			const formattedAddress = formatAddress([result.streetAddress, result.suburb, result.city, result.zipCode])
			setValue(formattedAddress)
		})
	}

	const handleAutocompleteChange = (event: React.SyntheticEvent, newValue: string | null) => {
		if (newValue) {
			const selectedPrediction = predictions.find((prediction) => prediction.description === newValue)
			if (selectedPrediction) {
				getHandleSelect(selectedPrediction.place_id)()
			}
		}
	}

	return (
		<Autocomplete
			disablePortal
			value={value}
			inputValue={value}
			freeSolo
			options={predictions.map((option) => option.description)}
			onInputChange={(_event, newInputValue) => {
				setValue(newInputValue)
			}}
			onChange={handleAutocompleteChange}
			renderInput={(params) => (
				<TextField
					variant="outlined"
					color="secondary"
					{...params}
					label="Street address (optional)"
					onChange={handleChange}
					onClick={handleClick}
				/>
			)}
		/>
	)
}

import { Product } from "@/types"
import { convertPriceToRand, convertProductNoVariants, extendedFetch, sortVariants } from "@/utils"
import flow from "lodash.flow"

export const getProductsFromCookies = async (merchantId: string, storeId: string): Promise<Product[]> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/product-management/v1/owners/Merchant-${merchantId}-${storeId}/catalogue/product`
	const result = await extendedFetch({ url })

	const { catalogueItems: products } = await result.json()

	const response = flow(convertProductNoVariants, sortVariants, convertPriceToRand)(products)

	return response as Product[]
}

import { BuilderMenuTitle } from "@/builderComponents"
import { TransparentDivider } from "@/components/TransparentDivider"
import { settingsUIAtom, SettingsUIState } from "@/state"
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline"
import { useTheme } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList/MenuList"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { CaretRight } from "@phosphor-icons/react"
import { useAtom, useSetAtom } from "jotai"
import React from "react"

type MenuItems = { label: string; uiState: SettingsUIState }[]

const menuItems: MenuItems = [
	{ label: "Pickup & delivery options*", uiState: SettingsUIState.PickupAndDelivery },
	{ label: "Colour styles", uiState: SettingsUIState.ColourStyles },
	{ label: "Store policies*", uiState: SettingsUIState.StorePolicies },
]

export default function SettingsMenu() {
	const setSettingsUI = useSetAtom(settingsUIAtom)

	return (
		<Stack>
			<BuilderMenuTitle title="Settings" />
			<Typography variant="body2" px="1rem" fontStyle="italic" color="text.secondary">
				Options marked with * are required.
			</Typography>
			<TransparentDivider height="1rem" />

			<SettingsUpdatedAlert />

			<MenuList>
				{menuItems.map((item) => (
					<MenuItem key={item.label} divider onClick={() => setSettingsUI({ uiState: item.uiState, alert: "" })}>
						<ListItemText>{item.label}</ListItemText>
						<CaretRight />
					</MenuItem>
				))}
			</MenuList>
		</Stack>
	)
}

function SettingsUpdatedAlert() {
	const { palette } = useTheme()

	const [{ alert }, setSettingsUI] = useAtom(settingsUIAtom)

	const handleClose = () => setSettingsUI({ uiState: SettingsUIState.None, alert: "" })

	React.useEffect(() => {
		const timeoutId = setTimeout(() => handleClose(), 5000 /* 5 seconds */)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [alert])

	return (
		<>
			{alert && (
				<Box p=".5rem 1rem">
					<Alert severity="success" icon={<CheckCircleOutline fontSize="inherit" />} onClose={handleClose}>
						<Typography variant="body2" color={palette.success.dark}>
							{alert}
						</Typography>
					</Alert>
				</Box>
			)}
		</>
	)
}

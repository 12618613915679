"use client"

import ErrorModal from "@/components/ErrorModal"
import { useSessionTimeout } from "@/hooks"
import { getAuthUrl } from "@/utils"
import Backdrop from "@mui/material/Backdrop"
import React from "react"

const timeout = 5
const redirect = () => {
	window.location.href = getAuthUrl()
}

const SessionExpiredModal = () => {
	const [seconds, setSeconds] = React.useState(timeout)
	const header = "Your session has expired"
	const description = `Your session has expired. Please click log in to continue or you will be automatically redirected to the login screen in ${seconds} seconds.`
	const sessionExpired = useSessionTimeout()

	React.useEffect(() => {
		if (sessionExpired) {
			const interval = window.setInterval(() => {
				setSeconds((prevSeconds) => prevSeconds - 1)
			}, 1000)
			return () => window.clearInterval(interval)
		}
	}, [sessionExpired])

	if (sessionExpired === false) return null

	if (seconds === 0) {
		redirect()
	}

	return (
		<Backdrop open>
			<ErrorModal header={header} description={description} handlerText={"Log in"} handleRetry={redirect} />
		</Backdrop>
	)
}

export default SessionExpiredModal

import { Profile, Store } from "@/types"
import { extendedFetch } from "@/utils"

export const getProfileFromCookies = async (merchantId: string): Promise<Profile> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/profile/v1/merchants/${merchantId}`
	const result = await extendedFetch({ url })

	const response = (await result.json()) as Profile

	return response
}

export const getStoresFromCookies = async (merchantId: string): Promise<Store[]> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/profile/v1/merchants/${merchantId}/stores`
	const result = await extendedFetch({ url })

	const response = (await result.json()) as Store[]

	return response
}

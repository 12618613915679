import { AddressComponent as Address } from "@/builderComponents"
import TextField, { getMaxCharacterMutationFn, trim } from "@/components/TextField"
import { TransparentDivider } from "@/components/TransparentDivider"
import { useContactValues, useUpdateDraftWebstoreConfigMutation } from "@/hooks"
import { usePickupInstructionsValues } from "@/hooks/usePickupInstructionsValues"
import { formControlAtom, pickupAndDeliveryFormControlsAtom } from "@/state"
import { button } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import CloseIcon from "@mui/icons-material/Close"
import InfoOutlined from "@mui/icons-material/InfoOutlined"
import LoadingButton from "@mui/lab/LoadingButton"
import { useTheme } from "@mui/material"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { PencilLine, Plus, Storefront } from "@phosphor-icons/react"
import { useAtomValue } from "jotai"
import React from "react"

const maxCharacterCount = 250
const blurMutationFns = [trim]
const changeMutationFns = [getMaxCharacterMutationFn(maxCharacterCount)]

const ofMaxCharactersText = (currentLength: number) => `Max characters ${currentLength}/${maxCharacterCount}`

const addTitle = "Add store address"
const addMessage = "Adding your store address here will also add it to the address in the store details section."

const editTitle = "Edit store address"
const editMessage = "Editing your store address here will also update the address in the store details section."

export default function Pickup() {
	const { palette } = useTheme()

	const contactValues = useContactValues(true)
	const [addressModalVisible, setAddressModalVisible] = React.useState(false)

	const pickupInstructions = usePickupInstructionsValues()

	const { handleOnChange, fieldErrors } = useAtomValue(pickupAndDeliveryFormControlsAtom)

	const maxCharactersHelperText = ofMaxCharactersText(pickupInstructions.length ?? 0)

	return (
		<Stack>
			<Typography variant="subtitle2" display="flex" alignItems="center" gap=".5rem">
				<Storefront size={24} /> Pickup option
			</Typography>
			<TransparentDivider height=".5rem" />

			<Typography variant="caption" color="text.secondary">
				Customers pick up their orders from your store.
			</Typography>
			<TransparentDivider />

			<Stack padding=".5rem" bgcolor={palette.grey[50]} borderRadius=".375rem">
				<Typography variant="caption" color="text.secondary">
					Store address
				</Typography>
				{contactValues.address ? (
					<>
						<Typography variant="body2" color="text.secondary">
							{contactValues.address}
						</Typography>
						<Button
							sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}
							color="secondary"
							variant="text"
							startIcon={<PencilLine size={18} />}
							onClick={() => setAddressModalVisible(true)}
						>
							<Typography sx={button.small} textTransform="none" color="secondary">
								Edit
							</Typography>
						</Button>
					</>
				) : (
					<>
						<Typography variant="body2" color="text.secondary">
							Add your street address so they know where to find you!
						</Typography>
						<Button
							sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}
							color="secondary"
							variant="text"
							startIcon={<Plus size={18} />}
							onClick={() => setAddressModalVisible(true)}
						>
							<Typography sx={button.small} textTransform="none" color="secondary">
								Add store address
							</Typography>
						</Button>
					</>
				)}
			</Stack>
			<TransparentDivider />

			<TextField
				sx={{ justifyContent: "center" }}
				color="secondary"
				name="instructions"
				variant="outlined"
				label="Pickup instructions (optional)"
				placeholder="e.g. Pickups are at the main entrance between 09:00 - 17:00"
				multiline
				helperText={fieldErrors.pickupInstructions?.message ?? maxCharactersHelperText}
				onChange={(e) => {
					const update = handleOnChange("pickupInstructions")
					update(e.target.value)
				}}
				value={pickupInstructions}
				fullWidth
				error={fieldErrors.pickupInstructions?.message}
				size="medium"
				blurMutationFns={blurMutationFns}
				changeMutationFns={changeMutationFns}
				minRows={4}
			/>
			<TransparentDivider />

			<Divider sx={{ height: 0 }} />
			<TransparentDivider />

			<AddressModal
				open={addressModalVisible}
				onClose={() => setAddressModalVisible(false)}
				title={contactValues.address ? editTitle : addTitle}
				message={contactValues.address ? editMessage : addMessage}
			/>
		</Stack>
	)
}

type AddressModalProps = {
	title: string
	message: string
	open: boolean
	onClose: () => void
}
function AddressModal(props: Readonly<AddressModalProps>) {
	const { palette } = useTheme()

	const { mutate: updateWebstoreConfig, isPending: updateWebstorePending } = useUpdateDraftWebstoreConfigMutation()

	const [address, setAddress] = React.useState("")

	const contactValues = useContactValues(true)

	const { handleOnChange } = useAtomValue(formControlAtom)

	const onSave = () => {
		handleOnChange("contact")({ ...contactValues, address })
		updateWebstoreConfig()
		props.onClose()
	}

	return (
		<Dialog
			PaperProps={{
				sx: {
					borderRadius: ".625rem",
					width: "27.75rem",
					maxHeight: "26.5rem",
				},
			}}
			open={props.open}
		>
			<DialogContent>
				<IconButton
					aria-label="close"
					onClick={props.onClose}
					sx={{
						position: "absolute",
						right: ".75rem",
						top: ".75rem",
						color: palette.common.black,
					}}
				>
					<CloseIcon />
				</IconButton>
				<Typography variant="h6">{props.title}</Typography>
				<TransparentDivider />

				<Alert icon={<InfoOutlined fontSize="inherit" />} severity="info">
					<Typography variant="body2" color={palette.info.dark}>
						{props.message}
					</Typography>
				</Alert>
				<TransparentDivider height="2rem" />

				<Address initialValue={contactValues.address ?? ""} onChange={(address) => setAddress(address)} />
				<TransparentDivider height="4rem" />

				<LoadingButton onClick={onSave} loading={updateWebstorePending} fullWidth variant="contained">
					Save
				</LoadingButton>
			</DialogContent>
		</Dialog>
	)
}

import { useGetWebstoreConfig } from "@/hooks/webstore"
import { pickupAndDeliveryFormControlsAtom } from "@/state"
import { useAtomValue } from "jotai"
import React from "react"

export const usePickupInstructionsValues = () => {
	const { webstoreConfigDraft } = useGetWebstoreConfig()

	const pickupInstructionsDraft = webstoreConfigDraft?.shipping?.pickupInstructions

	const { handleOnChange, values } = useAtomValue(pickupAndDeliveryFormControlsAtom)

	const formPickupInstructions = values.pickupInstructions

	const initialPickupInstructions = pickupInstructionsDraft ?? formPickupInstructions

	React.useEffect(() => {
		const updatePickupInstructionsContent = initialPickupInstructions ?? formPickupInstructions
		const update = handleOnChange("pickupInstructions")
		update(updatePickupInstructionsContent)
	}, [])

	return formPickupInstructions
}

import { useGetWebstoreConfig } from "@/hooks/webstore"
import { formControlAtom } from "@/state"
import { Hero } from "@/types"
import { useAtomValue } from "jotai"
import React from "react"

const defaultHeader = "Welcome to our store"
const defaultSubheading = "Explore our range of products and shop your favourites today"

export const useHeroContentValues = (touched = false) => {
	const { webstoreConfigDraft } = useGetWebstoreConfig()

	const heroContentDraft = webstoreConfigDraft?.hero

	const { handleOnChange, values } = useAtomValue(formControlAtom)

	const { header: formHeader, subheading: formSubheading, imageUrl: formImageUrl } = values.hero as Hero
	const { header: headerDraft, subheading: subheadingDraft, imageUrl } = heroContentDraft ?? {}

	const initialHeader = headerDraft ?? defaultHeader ?? formHeader
	const initialSubheading = subheadingDraft ?? defaultSubheading ?? formSubheading

	React.useEffect(() => {
		const updateHeroContent: Hero = {
			header: touched ? formHeader : initialHeader,
			subheading: touched ? formSubheading : initialSubheading,
			imageUrl: touched ? formImageUrl : imageUrl,
		}
		const update = handleOnChange("hero")
		update(updateHeroContent)
	}, [])

	return {
		header: formHeader,
		subheading: formSubheading,
		imageUrl: formImageUrl,
	}
}

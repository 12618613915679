import { useGetWebstoreConfig } from "@/hooks/webstore"
import { pickupAndDeliveryFormControlsAtom } from "@/state"
import { priceToRand } from "@/utils"
import { useAtomValue } from "jotai"
import React from "react"

export const useDeliveryFeeValues = () => {
	const { webstoreConfigDraft } = useGetWebstoreConfig()

	const deliveryFeeDraft = priceToRand(webstoreConfigDraft?.shipping?.deliveryFee ?? 0)

	const { handleOnChange, values } = useAtomValue(pickupAndDeliveryFormControlsAtom)

	const formDeliveryFee = values.deliveryFee

	const initialDeliveryFee = deliveryFeeDraft ?? formDeliveryFee

	React.useEffect(() => {
		const updateDeliveryFeeContent = initialDeliveryFee ?? formDeliveryFee
		const update = handleOnChange("deliveryFee")
		update(updateDeliveryFeeContent)
	}, [])

	return formDeliveryFee
}

import { throwIfNot2xx } from "@/api/helper/helper"
import { GetWebstoreCatalogueResponse, Products, Service, Services, WebstoreConfig } from "@/types"
import { convertPriceToRand, convertProductNoVariants, extendedFetch, sortVariants } from "@/utils"
import flow from "lodash.flow"

export const getServicesFromCookies = async (merchantId: string, storeId: string): Promise<Service[]> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/product-management/v1/owners/Merchant-${merchantId}-${storeId}/catalogue/service`
	const result = await extendedFetch({ url })

	const { catalogueItems: services } = await result.json()

	const response = flow(convertPriceToRand)(services)

	return response as Service[]
}

export const getWebstoreCatalogue = async (
	owner?: string,
	webstoreConfig?: WebstoreConfig,
): Promise<GetWebstoreCatalogueResponse> => {
	if (!owner || (!webstoreConfig?.catalogueItems?.productIds?.length && !webstoreConfig?.catalogueItems?.serviceIds?.length)) {
		return {} as GetWebstoreCatalogueResponse
	}

	const requestBody = {
		catalogueItems: {
			productIds: webstoreConfig?.catalogueItems?.productIds ?? [],
			serviceIds: webstoreConfig?.catalogueItems?.serviceIds ?? [],
		},
	}
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/product-management/v1/owners/${owner}/webstore-catalogue`

	const result = await fetch(url, {
		headers: {
			"Content-Type": "application/json",
		},
		method: "PUT",
		credentials: "omit",
		body: JSON.stringify(requestBody),
	})

	throwIfNot2xx(result)

	const json = (await result.json()) as GetWebstoreCatalogueResponse

	return {
		...json,
		catalogueItems: {
			products: flow(convertProductNoVariants, sortVariants, convertPriceToRand)(json.catalogueItems.products) as Products,
			services: flow(convertPriceToRand)(json.catalogueItems.services) as Services,
		},
	}
}

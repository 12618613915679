import { ColourPill } from "@/assets/ColourPill"
import { WebstoreConfigurationColor } from "@/enums"
import { colourThemeAtom, settingsUIAtom, SettingsUIState } from "@/state"
import { blue, green, grey, purple, white, yellow } from "@/templateThemes"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { ArrowLeft } from "@phosphor-icons/react"
import { motion } from "framer-motion"
import { useAtom } from "jotai"
import { useSetAtom } from "jotai/index"
import React from "react"

type ColourTheme = {
	themeName: WebstoreConfigurationColor
}

const map = {
	[WebstoreConfigurationColor.BLUE]: blue,
	[WebstoreConfigurationColor.GREEN]: green,
	[WebstoreConfigurationColor.GREY]: grey,
	[WebstoreConfigurationColor.PURPLE]: purple,
	[WebstoreConfigurationColor.WHITE]: white,
	[WebstoreConfigurationColor.YELLOW]: yellow,
}

const ColorContainer = (props: ColourTheme) => {
	const [colourTheme, setColourTheme] = useAtom(colourThemeAtom)

	const selected = (colourTheme ?? WebstoreConfigurationColor.WHITE) === props.themeName

	const theme = map[props.themeName]

	const handleClick = () => setColourTheme(props.themeName)

	return (
		<motion.div
			whileHover="hover"
			animate={{ scale: 1 }}
			variants={{
				hover: {
					scale: 1.05,
				},
			}}
		>
			<Stack
				sx={{
					width: "100%",
					height: "5rem",
					direction: "column",
					alignItems: "center",
					justifyContent: "center",
					border: selected ? "2px solid teal" : "1px solid #EEE",
					borderRadius: ".5rem",
					boxSizing: "border-box",
					background: theme.palette.secondary.main,
					cursor: "pointer",
				}}
				onClick={handleClick}
			>
				<Typography sx={alert.title}>Aa</Typography>
				<ColourPill fill={theme.palette.primary.main} />
			</Stack>
		</motion.div>
	)
}

export const StoreColours = () => {
	const setSettingsUI = useSetAtom(settingsUIAtom)

	return (
		<Stack p="1rem" gap="1rem">
			<Button
				sx={{ justifyContent: "flex-start" }}
				color="inherit"
				variant="text"
				startIcon={<ArrowLeft size={18} />}
				onClick={() => setSettingsUI({ uiState: SettingsUIState.None, alert: "" })}
			>
				<Typography sx={alert.title} textTransform="none">
					Colour styles
				</Typography>
			</Button>
			<Grid2 container columns={2} spacing={1.5}>
				{Object.keys(WebstoreConfigurationColor).map((theme) => (
					<Grid2 sm={1} key={theme}>
						<ColorContainer themeName={theme as WebstoreConfigurationColor} />
					</Grid2>
				))}
			</Grid2>
		</Stack>
	)
}

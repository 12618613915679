import { IkhokhaThemeProvider } from "@/builderComponents"
import { Fade } from "@/components/Fade"
import TemplateComponentThemeProvider from "@/components/Template/TemplateThemeProvider"
import { theme } from "@ikhokha/commons-ui/build/dist/cjs/theme"
import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import React from "react"

const zIndex = 2
const Tag = ({ text }: { text: string }) => {
	const ref = React.useRef<HTMLDivElement>(null)
	const [top, setTop] = React.useState(0)

	React.useLayoutEffect(() => {
		if (ref.current) {
			setTop(ref.current.clientHeight)
		}
	}, [])

	return (
		<Box
			ref={ref}
			sx={{
				position: "absolute",
				// Deduct extra to ensure the tag is positioned above the border
				top: -top + hoverWrapperPositionProps.top,
				left: -2,
				backgroundColor: "secondary.main", // Change this to your preferred color
				paddingX: ".25rem",
				border: "none",
				zIndex,
			}}
		>
			<Typography variant="caption" color={"common.white"}>
				{text}
			</Typography>
		</Box>
	)
}

/**
 * Top, left, right, and bottom are set to 0 to ensure
 * the hover effect covers the ENTIRE parent element.
 * We use negative values to ensure the border (in this case) is
 * visible over the parent element.
 */
const hoverWrapperPositionProps = {
	top: -2,
	left: -2,
	right: -2,
	bottom: -2,
}

export type HoverWrapperProps = {
	labelText: string
	overlay?: (props: React.PropsWithChildren) => JSX.Element
	/**
	 * If true, the hover effect will change to a solid border.
	 */
	active?: boolean
	onClick?: React.MouseEventHandler<HTMLDivElement>
}

/**
 * A wrapper component that displays a hover effect around its children.\
 * The only supported affect at the moment is a dotted border.\
 * Wrap any children that you want to have a hover effect with this component.
 */
export const HoverWrapper = React.forwardRef(function HoverWrapper(props: React.PropsWithChildren<HoverWrapperProps>, ref) {
	const [isHovered, setIsHovered] = React.useState(false)

	const handleMouseOver: React.MouseEventHandler<HTMLDivElement> = (event) => {
		event.stopPropagation()
		event.preventDefault()
		setIsHovered(true)
	}

	const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = (event) => {
		setIsHovered(false)
	}

	const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
		props.onClick?.(event)
	}

	/**
	 * Because this is conditionally rendered based on the hover state,
	 * we memoize it to prevent unnecessary re-renders.
	 */
	const hoverComponent = React.useMemo(
		() =>
			props.labelText ? (
				<Fade>
					<Tag text={props.labelText} />
					<Box
						sx={{
							position: "absolute",
							...hoverWrapperPositionProps,
							pointerEvents: "none",
							transition: "all 0.4s ease-in",
							border: `2px ${props.active ? "solid" : "dotted"} ${theme.palette.secondary.main}`,
							zIndex,
						}}
					/>
				</Fade>
			) : undefined,
		[props.labelText, props.active],
	)

	return (
		<IkhokhaThemeProvider>
			<Box
				/**
				 * Using mouseOver and mouseOut better supports whether children are hovered or not.
				 */
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseLeave}
				sx={{
					position: "relative", // Ensures the hover effect is contained within the parent element as it uses absolute positioning
					display: "inline-block",
					width: "100%",
					cursor: "pointer",
					height: "fit-content",
				}}
				onClick={handleClick}
				ref={ref}
			>
				{(isHovered || props.active) && hoverComponent}
				<Box
					sx={{
						/**
						 * By setting position: "relative", the Box itself is positioned relative to its normal position in the document flow. This is important because:
						 * 1. It allows the zIndex property to take effect, which wouldn’t work
						 * as expected if the position was still the default (static).
						 *
						 * 2. It maintains the layout flow of the document, meaning the inner Box will
						 * take up space normally but will be able to stack over the Paper due to the zIndex difference.
						 */
						position: "relative",
						width: "100%",
						height: "100%",
					}}
				>
					{props.overlay && (isHovered || props.active) && React.createElement(props.overlay)}
					{/* Need to override the ikhokha theme */}
					<TemplateComponentThemeProvider>{props.children}</TemplateComponentThemeProvider>
				</Box>
			</Box>
		</IkhokhaThemeProvider>
	)
})

export * from "./getWrapperOverlay"

import { SideMenuItem } from "@/enums"
import { useFinishComponentDraftEdit } from "@/hooks"
import { ActionType, builderMenuReducerAtom } from "@/state"
import { ColorLens, ColorLensOutlined, ListOutlined } from "@mui/icons-material"
import Icon from "@mui/material/Icon"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { Gear } from "@phosphor-icons/react"
import { useAtom } from "jotai"

type MenuItem = {
	text: string
	iconNode: React.ReactNode
	activeNode: React.ReactNode
	sideMenuItem: SideMenuItem
}

const menuItems: MenuItem[] = [
	{ text: "Guide", iconNode: <ListOutlined />, activeNode: <ListOutlined />, sideMenuItem: SideMenuItem.Setup_Guide },
	{ text: "Settings", iconNode: <Gear />, activeNode: <Gear />, sideMenuItem: SideMenuItem.Settings },
]

const MenuItem = (props: MenuItem) => {
	const [menuState, dispatch] = useAtom(builderMenuReducerAtom)
	const finishComponentDraftEdit = useFinishComponentDraftEdit()

	const handleClick = () => {
		dispatch({ type: ActionType.SideMenuItemSelected, payload: props.sideMenuItem })
		finishComponentDraftEdit()
	}

	const active = menuState.activeMenuItem === props.sideMenuItem

	return (
		<Stack
			sx={{ cursor: "pointer" }}
			direction={"column"}
			alignItems={"center"}
			onClick={handleClick}
			color={active ? "secondary.main" : "inherit"}
			borderBottom={active ? "2px solid" : "2px solid transparent"}
			width={"4.2rem"}
			boxSizing={"border-box"}
			height={"3.625rem"}
		>
			<Icon>{active ? props.activeNode : props.iconNode}</Icon>
			<Typography variant={active ? "body1" : "body2"} color={active ? "secondary.main" : "inherit"}>
				{props.text}
			</Typography>
		</Stack>
	)
}

export const SideMenu = () => {
	return (
		<Stack gap={"1rem"} height={"100%"}>
			{menuItems.map((item) => (
				<MenuItem key={item.text} {...item} />
			))}
		</Stack>
	)
}

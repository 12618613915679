import Spinner from "@/components/Spinner"
import { useWebstoreQueryCache } from "@/hooks"
import { SvgIcon } from "@mui/material"
import Stack from "@mui/material/Stack"
import { alpha, useTheme } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { CheckCircle, WarningCircle } from "@phosphor-icons/react"
import { useDebounce } from "@uidotdev/usehooks"
import React from "react"

export default function SaveState() {
	const { draftConfigUpdateSaving: saving, draftConfigUpdateError: error } = useWebstoreQueryCache()

	const [text, setText] = React.useState("")
	const debouncedText = useDebounce(text, 5000 /* 5 Seconds */)

	const theme = useTheme()

	const stateIcon = saving ? (
		<Spinner size={20} sx={{ color: "text.secondary" }} />
	) : (
		<SvgIcon sx={{ width: "1.25rem", height: "1.25rem", color: "text.secondary" }}>
			<CheckCircle color="inherit" />
		</SvgIcon>
	)

	const errorIcon = error && <WarningCircle color={theme.palette.error.main} />

	let icon = stateIcon
	if (!saving && errorIcon) {
		icon = errorIcon
	}

	React.useEffect(() => {
		setText(saving ? "Saving..." : "Autosaved")
		if (!saving && errorIcon) {
			setText("Auto saving failed")
		}
	}, [saving])

	React.useEffect(() => {
		setText("")
	}, [debouncedText])

	return (
		<Stack direction={"row"} alignItems={"center"} gap={"0.5rem"}>
			{icon}
			<Typography
				variant="caption"
				sx={{
					color: errorIcon ? theme.palette.error.main : alpha(theme.palette.text.secondary, 0.6),
				}}
				lineHeight={0}
			>
				{text}
			</Typography>
		</Stack>
	)
}

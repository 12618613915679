import Delivery from "@/components/builder/ActionPanel/Settings/PickupAndDelivery/Delivery"
import Pickup from "@/components/builder/ActionPanel/Settings/PickupAndDelivery/Pickup"
import { TransparentDivider } from "@/components/TransparentDivider"
import { WebstoreFulfilment } from "@/enums"
import { useUpdateDraftWebstoreConfigMutation } from "@/hooks"
import { fulfilmentAtom, pickupAndDeliveryFormControlsAtom, settingsUIAtom, SettingsUIState } from "@/state"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import LoadingButton from "@mui/lab/LoadingButton"
import { styled } from "@mui/material"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import MUIRadio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { ArrowLeft } from "@phosphor-icons/react"
import { useAtom } from "jotai"
import { useAtomValue, useSetAtom } from "jotai/index"
import React from "react"

const Radio = styled(MUIRadio)(() => ({
	"&.MuiRadio-root.Mui-checked": {
		color: "teal",
	},
}))

export default function PickupAndDelivery() {
	const {
		mutate: updateWebstoreConfig,
		isPending: updateWebstorePending,
		isSuccess: updateWebstoreSuccess,
	} = useUpdateDraftWebstoreConfigMutation()

	const { fieldErrors } = useAtomValue(pickupAndDeliveryFormControlsAtom)

	const setSettingsUI = useSetAtom(settingsUIAtom)
	const [fulfilment, setFulfilment] = useAtom(fulfilmentAtom)

	if (updateWebstoreSuccess) {
		setSettingsUI({
			uiState: SettingsUIState.None,
			alert: "Pickup and delivery options updated!",
		})
	}

	const { pickupInstructions, deliveryFee } = fieldErrors
	const saveDisabled = pickupInstructions !== null || deliveryFee !== null

	return (
		<Stack padding="1rem">
			<Button
				sx={{ justifyContent: "flex-start" }}
				color="inherit"
				variant="text"
				startIcon={<ArrowLeft size={18} />}
				onClick={() => setSettingsUI({ uiState: SettingsUIState.None, alert: "" })}
			>
				<Typography sx={alert.title} textTransform="none">
					Pickup & delivery options
				</Typography>
			</Button>
			<TransparentDivider />

			<FormControl>
				<FormLabel id="demo-radio-buttons-group-label">
					<Typography variant="body1">How will your customers get their orders?</Typography>
				</FormLabel>
				<TransparentDivider height=".5rem" />

				<RadioGroup
					color="secondary"
					aria-labelledby="demo-radio-buttons-group-label"
					name="radio-buttons-group"
					value={fulfilment}
					onChange={(e) => setFulfilment(e.target.value as WebstoreFulfilment)}
				>
					<FormControlLabel value={WebstoreFulfilment.PICKUP} control={<Radio />} label="Pickup" />
					<FormControlLabel value={WebstoreFulfilment.DELIVERY} control={<Radio />} label="Delivery" />
					<FormControlLabel value={WebstoreFulfilment.PICKUP_OR_DELIVERY} control={<Radio />} label="I'll use both" />
				</RadioGroup>
			</FormControl>
			<TransparentDivider />

			<Divider sx={{ height: 0 }} />
			<TransparentDivider />

			{(fulfilment === WebstoreFulfilment.PICKUP || fulfilment === WebstoreFulfilment.PICKUP_OR_DELIVERY) && <Pickup />}

			{(fulfilment === WebstoreFulfilment.DELIVERY || fulfilment === WebstoreFulfilment.PICKUP_OR_DELIVERY) && <Delivery />}

			<LoadingButton
				onClick={() => updateWebstoreConfig()}
				loading={updateWebstorePending}
				fullWidth
				disabled={saveDisabled}
				variant="contained"
			>
				Save
			</LoadingButton>
			<TransparentDivider height="1rem" />
		</Stack>
	)
}

import TextField from "@/components/TextField"
import { TransparentDivider } from "@/components/TransparentDivider"
import { useDeliveryFeeValues } from "@/hooks/useDeliveryFeeValues"
import { pickupAndDeliveryFormControlsAtom } from "@/state"
import { formatCurrency } from "@/utils"
import Divider from "@mui/material/Divider"
import InputAdornment from "@mui/material/InputAdornment"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { Truck } from "@phosphor-icons/react"
import { useAtomValue } from "jotai"
import React from "react"

export default function Delivery() {
	const deliveryFee = useDeliveryFeeValues()

	const { handleOnChange, fieldErrors } = useAtomValue(pickupAndDeliveryFormControlsAtom)

	const [displayPrice, setDisplayPrice] = React.useState("")

	React.useEffect(() => {
		if (deliveryFee !== displayPrice) {
			formatPrice()
		}
	}, [deliveryFee])

	const formatPrice = () => {
		const displayFee = formatCurrency(Number(deliveryFee))
		setDisplayPrice(displayFee)
	}

	return (
		<Stack>
			<Typography variant="subtitle2" display="flex" alignItems="center" gap=".5rem">
				<Truck size={24} /> Delivery option
			</Typography>
			<TransparentDivider height=".5rem" />

			<Typography variant="caption" color="text.secondary">
				Deliver your orders for free or a fee.
			</Typography>
			<TransparentDivider />

			<TextField
				color="secondary"
				required
				error={fieldErrors.deliveryFee?.message}
				helperText={fieldErrors.deliveryFee?.message}
				name="fee"
				label="What’s your delivery fee?"
				variant="outlined"
				value={displayPrice}
				onChange={(e) => {
					const update = handleOnChange("deliveryFee")
					update(e.target.value)
					setDisplayPrice(e.target.value)
				}}
				onBlur={(e) => {
					const update = handleOnChange("deliveryFee")
					update(e.target.value)
					formatPrice()
				}}
				type="text"
				InputProps={{
					startAdornment: (
						<InputAdornment
							sx={{
								"& .MuiTypography-root": {
									color: "#1D1D1BDE",
								},
							}}
							position="start"
						>
							R
						</InputAdornment>
					),
				}}
			/>
			<TransparentDivider height=".5rem" />

			<Typography variant="caption" color="text.secondary">
				*This fee is charged for the entire order and not per product.
			</Typography>
			<TransparentDivider height="1rem" />

			<Divider sx={{ height: 0 }} />
			<TransparentDivider />
		</Stack>
	)
}

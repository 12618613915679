import TextField, { trim } from "@/components/TextField"
import { useSocialsValues } from "@/hooks"
import { builderErrorStateAtom, EditItem, formControlAtom } from "@/state"
import { SocialPages } from "@/types"
import Stack from "@mui/material/Stack"
import { useAtomValue, useSetAtom } from "jotai"
import React from "react"

export const Socials = () => {
	const setErrorAtom = useSetAtom(builderErrorStateAtom)
	const { tiktok, instagram, facebook } = useSocialsValues(true)
	const socialPages: SocialPages = { tiktok, instagram, facebook }
	const { handleOnChange, fieldErrors } = useAtomValue(formControlAtom)

	const errorMessage = fieldErrors.socialPages?.message

	type SocialTextFieldProps = {
		name: string
		label: string
		value: string
		onChange: (_value: string) => void
	}
	const trimMutation = trim
	const socialTextField: React.FC<SocialTextFieldProps> = ({ name, label, value, onChange }) => (
		<TextField
			onChange={(e) => onChange(e.target.value)}
			value={value}
			name={name}
			label={label}
			error={fieldErrors.socialPages?.path === name}
			variant="filled"
			fullWidth
			changeMutationFns={[trimMutation]}
			blurMutationFns={[trimMutation]}
			helperText={getErrorMessage(name) ?? helperText[name]}
		/>
	)

	const helperText: Record<string, string> = {
		instagram: "https://www.instagram.com/yourpage",
		facebook: "https://www.facebook.com/yourpage",
		tiktok: "https://www.tiktok.com/@yourpage",
	}
	const fieldHasError = (field: string) => {
		return fieldErrors.socialPages?.path === field
	}

	const getErrorMessage = (field: string): string | undefined => {
		return fieldHasError(field) ? errorMessage : undefined
	}

	React.useEffect(() => {
		setErrorAtom((prev) => ({
			...prev,
			[EditItem.Socials]: fieldHasError("facebook") || fieldHasError("instagram") || fieldHasError("tiktok"),
		}))
	}, [fieldErrors.socialPages])

	return (
		<Stack marginTop={3} marginX={2} gap={3}>
			{socialTextField({
				name: "facebook",
				label: "Facebook URL (optional)",
				value: facebook ?? "",
				onChange: (value) => handleOnChange("socialPages")({ ...socialPages, facebook: value }),
			})}
			{socialTextField({
				name: "instagram",
				label: "Instragram URL (optional)",
				value: instagram ?? "",
				onChange: (value) => handleOnChange("socialPages")({ ...socialPages, instagram: value }),
			})}
			{socialTextField({
				name: "tiktok",
				label: "TikTok URL (optional)",
				value: tiktok ?? "",
				onChange: (value) => handleOnChange("socialPages")({ ...socialPages, tiktok: value }),
			})}
		</Stack>
	)
}
